"use client"
import React, { useContext, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import toast from 'react-hot-toast'
import { signIn } from '../../../service/service.user'
import { useNavigate } from 'react-router-dom'
import { resendVerificationCode, verifyUserOtp } from '../../../service/userService'
import * as Yup from 'yup';
import Authentication from '../components/Authentication'
import Verification from '../components/Verification'
import { UserContext } from '../../../context/user.context'
import ForgotPassword from './components/ForgotPassword'
import Login from './components/Login'
import localStorage from '../../../utils/localStorage'
import { redirectUser } from '../../../utils/Utils'


const loginValidation = Yup.object().shape({
    email: Yup.string().email('Invalid Email').required('Email is required'),
    password: Yup.string().min(8, 'Password must be of minimum 8 letters').required(),
});
const LoginPage = () => {
    const [showForgotScreen, setshowForgotScreen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [customError, setCustomError] = useState({});
    const [userId, setUserId] = useState(0);
    const [timer, setTimer] = useState(0);
    const [showVerification, setShowVerification] = useState(false);
    const [email, setEmail] = useState("");
    const { login, user, logout } = useContext(UserContext);
    const [isAuth, setIsAuth] = useState(false);
    const [loadFullScreen, setLoadFullScreen] = useState(false);
    const router = useNavigate();

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const emailParam = params.get('email');
        if (emailParam) {
            formik.setFieldValue('email', emailParam);
            setTimeout(() => {
                document.getElementById('email').value = emailParam;
            }, 1000);
        }
    }, []);

    const handleToggle = () => {
        setshowForgotScreen(!showForgotScreen)
    }

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: loginValidation,

        onSubmit: async (data) => {
            setIsLoading(true);
            await signIn(data)
                .then((response) => {
                    login(response.data);
                    redirectUser(router)
                    setIsLoading(false);
                })
                .catch((e) => {
                    if (
                        e.response &&
                        e.response.data &&
                        e.response.data.data &&
                        e.response.data.data.user_id &&
                        e.response.data.data.msg === "verify"
                    ) {
                        setUserId(e.response.data.data.user_id);
                        setEmail(data.email);
                        setShowVerification(true);
                        setIsLoading(false);
                    } else {
                        toast.error("Invalid credentials!");
                        setIsLoading(false);
                    }
                });
        },
    });

    const { handleSubmit, touched, errors, getFieldProps } = formik;

    const submitVerification = async (verificationCode) => {
        setCustomError({})
        setIsLoading(true);
        await verifyUserOtp({
            verification_code: verificationCode,
            user_id: userId,
        })
            .then(async (res) => {
                if (res.status === 200 && res.data && res.data.user) {
                    toast.success("Verification completed!");
                    login(res.data);
                    redirectUser(router)
                    setIsLoading(false);
                } else if (res.data && res.data.error) {
                    setCustomError(res.data.error);
                    setIsLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    };

    const startTimer = () => {
        const interval = setInterval(() => {
            setTimer(prevTimer => {
                if (prevTimer === 0) {
                    clearInterval(interval);
                    return 0;
                } else {
                    return prevTimer - 1;
                }
            });
        }, 1000);
    };

    const resendOtp = async () => {
        if (timer !== 0) {
            return;
        }
        await resendVerificationCode({ user_id: userId })
            .then((res) => {
                toast.success("Code sent successfully!");
                setTimer(60)
                startTimer()
            })
            .catch((err) => {
                toast.error("Unable to resend code!");
                console.log(err);
            });
    };

    const ssoTo = (type) => {
        const token = localStorage.getItem("token");
        if (token) {
            if (type === 1) {
                window.location.href =
                    (window.location.host.includes("localhost:")
                        ? `http://localhost:3000/`
                        : `https://creatorstock.io/`) + `sso/${token}`;
            } else {
                window.location.href =
                    (window.location.host.includes("localhost:")
                        ? `http://localhost:3000/`
                        : `https://cloud.creatorstock.io/`) + `sso/${token}`;
            }
        }
    };

    // const logoutUser = async () => {
    //     setLoadFullScreen(true);
    //     await logoutCurrentUser();
    //     setIsAuth(false);
    //     logout();
    //     setLoadFullScreen(false);
    // };

    return (
        <Authentication>
            {!showForgotScreen ? (
                !showVerification ? (
                    <Login
                        onSubmit={handleSubmit}
                        isLoading={isLoading}
                        touched={touched}
                        errors={errors}
                        getFieldProps={getFieldProps}
                        handleToggle={handleToggle}
                    />
                ) : (
                    <Verification
                        timer={timer}
                        isLoading={isLoading}
                        email={email}
                        submitVerification={submitVerification}
                        resendOtp={resendOtp}
                        error={customError.verification_code}
                    />
                )
            ) : (
                <ForgotPassword handleToggle={handleToggle} />
            )}
        </Authentication>
    )
}

export default LoginPage
