import moment from "moment";
import {IoMdCheckmark} from "react-icons/io";
import Button from '../../components/buttons/Button'

const PlanCard = ({plan, style, buttontxt, theme = false, onClick = null, active,choosen, activePlanId}) => {

    const isChoosen = choosen === plan.id;

    return (
        <div className={`flex flex-col gap-4 ${style} ${active ? 'bg-theme-peach-100':''}`}>
            <div className={"flex flex-col gap-2 items-center"}>
                <span className={"text-xl font-bold"}>{plan.title}</span>
                <span className={"text-sm font-medium"}>{plan.package_price > 0 ? '$'+plan.package_price+'/month':'Free Plan'}</span>
                <span className={"text-sm text-white/50 text-center font-medium"}>{plan.sub_title}</span>
            </div>
            <div className={"w-full h-[1px] bg-border"}/>
            <div className={"flex flex-col gap-4 md:h-[200px]"}>
                {plan.heading &&
                    <div className="text-md">Features</div>
                }
                {plan.features_list.map((item, index) => {
                    return (
                        <div key={index} className={"text-sm flex gap-2 items-center"}>
                            <IoMdCheckmark className={"text-xl font-bold text-theme-peach-100"}/>
                            {item.text}
                        </div>
                    )
                })
                }
            </div>
            {!active && plan.package_price > 0 && <div className={""}>
                {theme ?
                    <button
                        disabled={isChoosen}
                        onClick={() => onClick('create')}
                        className={`w-full text-sm text-black font-medium h-10 flex justify-center items-center
                         bg-theme-peach-100 hover:bg-theme-peach-200 rounded-md`}>
                        {isChoosen ? "Processing...":buttontxt}
                    </button> :
                    <button
                        disabled={isChoosen}
                        onClick={() => onClick('create')}
                        className={`w-full text-sm text-white h-10 flex justify-center items-center
                         border border-border hover:bg-white hover:text-black rounded-md`}>
                        {isChoosen ? "Processing...":buttontxt}
                    </button>}
            </div>}
            {active && !plan.subscription.cancellation_scheduled && <div className={""}>
                    <button
                        disabled={isChoosen}
                        onClick={() => onClick('cancel')}
                        className={`w-full text-sm text-black bg-white h-10 flex justify-center items-center
                         border border-border rounded-md`}>
                        {isChoosen ? "Processing...":buttontxt}
                    </button>
            </div>}
            {active && plan.subscription.cancellation_scheduled && <div className={""}>
                    <button
                        disabled={isChoosen}
                        onClick={() => onClick('keep')}
                        className={`w-full text-sm text-white h-10 flex justify-center items-center
                         border border-border rounded-md`}>
                        {isChoosen ? "Processing...":"Keep Plan"}
                    </button>
            </div>}
            {active && plan.subscription?.cancellation_scheduled && <span className={"text-sm text-white/90 flex items-center font-medium"}>Expiring: {moment.unix(plan.subscription?.end_date).format('DD MMM YY')} </span>}
            {active && !plan.subscription?.cancellation_scheduled && <span className={"text-sm text-white/90 flex items-center font-medium"}>Renews: {moment.unix(plan.subscription?.end_date).format('DD MMM YY')} </span>}
            {plan.package_price <= 0 && !active && !activePlanId && <Button classnames={"md:mt-5 !bg-transparent !hover:bg-transparent !text-white !hover:text-white justify-center border-2 border-white"} disabled={true} name={"Active"} className="btn" />}
        </div>
    )
}
export default PlanCard;



export const PlanCardSkeleton = () => {
    return (
        <div className="flex flex-col gap-4 w-full rounded-xl text-white border p-4 border-white/50">
            <div className="flex flex-col gap-2 items-center">
                <div className="h-6 w-32 bg-gray-600 rounded animate-pulse"></div>
                <div className="h-4 w-24 bg-gray-600 rounded animate-pulse mt-1"></div>
                <div className="h-4 w-48 bg-gray-600 rounded animate-pulse mt-1"></div>
            </div>
            <div className="w-full h-[1px] bg-border"/>
            <div className="flex flex-col gap-4 md:h-[200px]">
                <div className="text-md "></div>
                {[1,2,3,4].map((item) => (
                    <div key={item} className="flex gap-2 items-center">
                        <div className="h-5 w-5 bg-gray-600 rounded-full animate-pulse"></div>
                        <div className="h-4 w-40 bg-gray-600 rounded animate-pulse"></div>
                    </div>
                ))}
            </div>
            <div>
                <div className="h-10 w-full bg-gray-600 rounded animate-pulse"></div>
            </div>
            <div className="h-4 w-48 bg-gray-600 rounded animate-pulse"></div>
        </div>
    )
}
