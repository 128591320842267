import React, { useContext, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { IoChevronUpCircleOutline, IoClose } from 'react-icons/io5'
// import EmptyPage from '../emptyPage/EmptyPage';
// import Button from '../buttons/Button'
// import {getContentById} from '../../../../service/service.content'
// import {SUCCESS200, SUCCESS201} from '../../../../constants/constants'
// import {convertSize} from '../../../../utils/Utils'
import moment from 'moment'
// import NoThumb from '../../../../assets/images/no-thumb.jpg'
// import {AiOutlineCalendar} from 'react-icons/ai'
// import {HiShieldCheck} from 'react-icons/hi'
// import VideoPlayer from '../videoPlayer/VideoPlayer'
// import {FaFile} from "react-icons/fa";
// import Thumb from "../../../../assets/images/no-thumb.jpg"
import { Disclosure } from '@headlessui/react'
import { UserContext } from '../../../../context/user.context';

const SaleDetailModal = ({ folder, isOpen, closeModal, id }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [folderData, setFolderData] = useState(folder);
    const [orderDetails, setOrderDetails] = useState(folder.order_details);
    const { user } = useContext(UserContext);

    useEffect(() => {
        if (!id) return


    }, [id])

    const parsePaymentAttributes = (payment) => {
        if (!payment) {
            return null;
        }
        return JSON.parse(payment.attribute);
    }
    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal} open={isOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/15" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel
                                className="w-full max-w-md transform overflow-hidden rounded-2xl bg-theme-1 text-left align-middle shadow-xl transition-all">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-white pb-4 p-6"
                                >
                                    <div className='flex justify-between items-center'>
                                        <span className='text-[16px] truncate'>View Sale Detail</span>
                                        <IoClose className='cursor-pointer text-[20px]' onClick={closeModal} />
                                    </div>
                                </Dialog.Title>

                                {isLoading ? (
                                    <div className='flex justify-center items-center text-white mt-4 font-inter h-40'>
                                        Loading...
                                    </div>
                                )
                                    :
                                    <div className="font-inter">
                                        <div className='text-white'>
                                            {orderDetails.length > 0 ?
                                                <Fragment>
                                                    <div className="w-full">
                                                        <div className="mx-auto w-full max-w-md rounded-2xl p-2">
                                                            {orderDetails.filter((order) => folderData.ownership === 'owner' ? true : order.user_id !== user?.userData?.id).map((order) => (
                                                                <Disclosure>
                                                                    {({ open }) => (
                                                                        <>
                                                                            <Disclosure.Button
                                                                                className="flex w-full justify-between rounded-lg bg-theme-peach-100 px-4 py-2 text-left text-sm font-medium text-white focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75">
                                                                                <span>{order.client.name} ({moment(order.created_at).format("DD/MM/YYYY")})</span>

                                                                                <IoChevronUpCircleOutline
                                                                                    className={`${open ? 'transform' : 'rotate-180'
                                                                                        } h-5 w-5 text-white`}
                                                                                />
                                                                            </Disclosure.Button>
                                                                            <Disclosure.Panel
                                                                                className="px-4 pb-2 pt-4 text-sm text-gray-500">
                                                                                    {order.user_id !== user?.id ? <>Buyer: {order.client.name}</> : <>Seller: {folder.user.name}</>} ({order.user_id !== user?.id ? order.client.email : folder.user.email})
                                                                                {!!folderData.usage_price &&
                                                                                    <p>Usage Paid:
                                                                                        £ {folderData.usage_price}</p>}
                                                                                {parsePaymentAttributes(order.payment) && parsePaymentAttributes(order.payment).ad_usage && !!folderData.ad_price_month &&
                                                                                    <p>Ad Usage:
                                                                                        £ {folderData.ad_price * folderData.ad_price_month}</p>}
                                                                                {parsePaymentAttributes(order.payment) && parsePaymentAttributes(order.payment).raw_usage && !!folderData.raw_usage &&
                                                                                    <p>Raw Usage:
                                                                                        £ {folderData.raw_price}</p>}
                                                                                {parsePaymentAttributes(order.payment) && parsePaymentAttributes(order.payment).misc_usage && !!folderData.misc_usage &&
                                                                                    <p>Add-on’s Usage:
                                                                                        £ {!!folderData.misc.length && folderData.misc.reduce((prev, curr) => prev + curr.price, 0)}</p>}
                                                                                <p>Total Paid: £ {order.price}</p>
                                                                            </Disclosure.Panel>
                                                                        </>
                                                                    )}
                                                                </Disclosure>))}
                                                        </div>
                                                    </div>
                                                </Fragment>
                                                :
                                                <div className="flex flex-col w-full p-2 items-center bg-black/70">
                                                    <span className='flex justify-center relative h-10 items-center'>
                                                        No Sales
                                                    </span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

export default SaleDetailModal
