import React from 'react'
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { useState } from 'react';


const AuthInputFiled = ({ label, type = "text", placeholder, name, value, error, register, id=null }) => {
    return (
        <div className="flex flex-col gap-2">
            <label
                className="text-white text-sm font-normal p-0 w-full">
                {label}
            </label>
            <input
                {...register}
                className="rounded-sm box-border bg-white/20 h-12 text-white m-0 px-4 w-full outline-none focus:outline-none text-sm"
                type={type}
                placeholder={placeholder}
                name={name}
                value={value}
                id={id}
            />
            {error ?
                <span className="text-red text-sm relative left-0 top-[-2px]">
                    {error}
                </span>
                :
                null
            }
        </div>
    )
}


export const PasswordInput = ({ label, type, placeholder, name, value, register, error }) => {
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className="flex flex-col gap-2">
            <label className="text-white text-sm font-normal p-0 w-full">
                {label}
            </label>
            <div className="relative">
                <input
                    {...register}
                    className="rounded-sm box-border bg-white/20 text-white h-12 m-0 px-4 w-full outline-none focus:outline-none text-sm"
                    type={showPassword ? 'text' : 'password'}
                    placeholder={placeholder}
                    name={name}
                    value={value}
                />
                <button
                    type="button"
                    className="absolute right-4 top-1/2 text-[20px] transform -translate-y-1/2 text-gray-400"
                    onClick={() => togglePasswordVisibility()}
                >
                    {!showPassword ? (
                        <FaEye />
                    ) : (
                        <FaEyeSlash />
                    )}
                </button>
            </div>
            {error && (
                <span className="text-red-600 text-sm relative left-0 top-[-2px]">
                    {error}
                </span>
            )}
        </div>
    );
};

export default AuthInputFiled
