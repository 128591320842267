import React, { useEffect, useState } from "react";
import DefaultDropdown from "../../components/buttons/defalutDropdown";
import { selectFolderTypes } from "../../../../utils/Data";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { IoIosFolder } from "react-icons/io";
const FolderListView = ({
  item,
  deleteFile,
  openShareModal,
  openEditModal,
  openSaleDetailModal = () => {},
  setSearchText,
  downloadFolder = () => null,
  isDownloading,
}) => {
  console.log(item);
  const [menu, setMenu] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    let menuItems = [];
    if (item.folder?.ownership === "owner") {
      menuItems = [
        {
          label: "Share",
          onClick: openShareModal,
        },
        {
          label: "Edit",
          onClick: openEditModal,
        },
        {
          label: "Delete",
          onClick: deleteFile,
        },
      ];
    }

    menuItems.push({
      label: "Download",
      onClick: downloadFolder,
    });

    if (
      item.folder?.type === 1 &&
      item.folder?.ownership === "shared_view" &&
      item.meta?.share
    ) {
      menuItems.push({
        label: "Purchase",
        // onClick: () => window.open(`https://cloud.${MAIN_DOMAIN}/shared/folder/${item.meta.share.uuid}`)
        onClick: () => window.open(`/shared/folder/${item.meta.share.uuid}`),
      });
    }

    if (
      item.folder?.type > 0 &&
      (item.folder?.ownership === "owner" ||
        item.folder?.ownership === "purchased")
    ) {
      menuItems.push({
        label: "Sale Detail",
        onClick: openSaleDetailModal,
      });
    }

    setMenu(menuItems);
  }, [item]);

  const openFolder = () => {
    setSearchText("");
    navigate(`/cloud/drive/${item?.id}`);
  };

  return (
    <tr className={"border-b border-theme-5"}>
      <td className="border-0 p-4 cursor-pointer" onClick={openFolder}>
        <div className="flex items-center gap-2">
          <span className={`text-3xl text-white/60`}>
            <IoIosFolder />
            {/*{item.meta?.svg}*/}
          </span>
          {item?.folder?.name || item.meta?.name}
        </div>
      </td>
      <td
        className="border-0 px-4 py-2 truncate max-w-[300px] cursor-pointer"
        onClick={openFolder}
      >
        {item.folder?.ownership === "owner"
          ? "-"
          : item?.folder?.user?.name || item?.folder?.user?.email}
      </td>
      <td
        className="border-0 px-4 py-2 truncate max-w-[300px] cursor-pointer"
        onClick={openFolder}
      >
        {
          selectFolderTypes.find((type) => type.value === item?.folder?.type)
            ?.label
        }
      </td>
      <td
        className="border-0 px-4 py-2 truncate max-w-[300px] cursor-pointer"
        onClick={openFolder}
      >
        <div className={"flex gap-1 text-gray-400 items-center"}>
          <div
            className={`w-2 h-2 rounded-full ${
              item.folder?.ownership === "owner"
                ? "bg-red"
                : item.folder?.ownership === "shared_view"
                ? "bg-green-primary"
                : "bg-yellow"
            }`}
          />
          <span
            className={` ${
              item.folder?.ownership === "owner"
                ? "text-red"
                : item.folder?.ownership === "shared_view"
                ? "text-green-primary"
                : "text-yellow"
            }`}
          >
            {/* {item.folder?.ownership === 'owner' ? "Owner" : item.folder?.ownership === 'shared_view' ? "Shared" : "Purchased"} */}
            {item.folder?.ownership === "owner" ? (
              "Owner"
            ) : item.folder?.ownership === "shared_view" ? (
              "Shared"
            ) : (
              <span className="flex items-center gap-2">
                <span>Purchased</span>
                <span>
                  {item.folder?.ownership === "purchased" ? (
                    <>
                      <p className="text-white">({item.folder?.user?.name}) </p>
                    </>
                  ) : (
                    ""
                  )}
                </span>
              </span>
            )}
          </span>
        </div>
      </td>
      <td
        className="border-0 px-4 py-2 truncate max-w-[300px] cursor-pointer"
        onClick={openFolder}
      >
        {moment(item?.folder?.created_at).format("MMM DD, YYYY")}
      </td>
      <td className="border-0 px-4 py-2">
        {item.meta?.canDelete === 0 || menu.length <= 0 ? (
          <span className="px-4" />
        ) : (
          <button className={`flex flex-0 items-center h-10 px-4`}>
            <DefaultDropdown
              data={item}
              content={menu}
              isDownloading={isDownloading}
            />
          </button>
        )}
      </td>
    </tr>
  );
};

export default FolderListView;
