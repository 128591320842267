"use client";
import React from "react";
import { Link } from "react-router-dom";
import AuthInputFiled, {
  PasswordInput,
} from "../../../components/inputs/input.auth";
import AuthButton from "../../../components/buttons/authButton";
import Logo from "../../../../assets/images/creatorstock.svg";

const Login = ({
  handleToggle,
  getFieldProps = () => null,
  isLoading,
  touched,
  errors,
  onSubmit
}) => {
  return (
    <div className="[h-screen overflow-y-auto w-full relative bg-banner bg-cover h-screen bg-black">
      <div className="flex justify-center items-center h-full">
        <div className="max-w-[500px] px-5 w-full py-10">
          <form>
            <div className={"flex flex-col gap-4"}>
              <img
                src={Logo}
                className="w-16 object-cover align-middle m-auto"
                alt="loading logo"
              />
              <h2 className="text-white text-2xl lg:text-3xl font-normal mb-1 text-center">
                Welcome to{" "}
                <span className="font-semibold text-2xl md:!text-3xl">
                  CreatorStock
                </span>
              </h2>
              <p className="text-white text-sm font-normal text-center">
                Please login to your account
              </p>
            </div>
            <div className="mt-8 mb-3">
              <AuthInputFiled
                error={touched.email && errors.email}
                register={getFieldProps("email")}
                label={"Email"}
                name={"email"}
                placeholder={"email@xyz.com"}
                id={"email"}
              />
            </div>
            <div className="my-3">
              <PasswordInput
                type="password"
                error={touched.password && errors.password}
                register={getFieldProps("password")}
                label={"Password"}
                name={"password"}
                placeholder={"Password"}
              />
            </div>
            <div className="flex justify-end w-full mb-4">
              <span
                onClick={handleToggle}
                className="text-white float-right cursor-pointer"
              >
                Forgot Password?
              </span>
            </div>
            <div className="flex justify-center items-center mt-4">
              <AuthButton
                disabled={isLoading}
                name="Login"
                onClick={onSubmit}
                type={"submit"}
                isLoading={isLoading}
              />
            </div>
            <div className="flex items-center justify-center text-white text-[16px] mt-2 font-normal gap-[4px] leading-[16px] text-center">
              Don't have an account?
              <Link className="hover:underline" to="/auth/register">
                Sign Up
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
