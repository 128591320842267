import React from 'react'

const Button = ({ icon = null, style, icon_position = "default", name, onClick, disabled = false , classnames}) => {
    return (
        <button
            className={`px-4 h-10 flex items-center text-white text-[14px] font-medium border-0 bg-theme-5 whitespace-nowrap 
                rounded-[4px] hover:bg-white hover:text-black group ${classnames}`}
            onClick={onClick}
            disabled={disabled}
        >
            {icon_position === 'default' ? (
                <>
                    {name} {icon}
                </>
            ) : (
                <>
                    {icon} {name}
                </>
            )}
        </button>
    )
}

export default Button
