import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { UploaderContext } from "../../../../context/uploader.context";
import { FileManagerContext } from "../../../../context/FileManagerContext";
import AddFolderModal from "../modals/AddFolderModal";
import { FaFolderPlus } from "react-icons/fa";
import { MdUpload } from "react-icons/md";
import UploadModal from "../modals/UploadModal";

function EmptyPageDrive({ content }) {
  const { id } = useParams();
  const { addFile } = useContext(UploaderContext);
  const [showAddFolder, setShowAddFolder] = useState(false);
  const fileManagerData = useContext(FileManagerContext);
  const [folderId, setFolderId] =
    fileManagerData && fileManagerData.folderIdData
      ? fileManagerData.folderIdData
      : [null, null];

  const openAddFolder = () => {
    setShowAddFolder(true);
  };

  const closeAddFolder = () => {
    setShowAddFolder(false);
  };

  const openUploadMOdal = () => {
    document.getElementById("upload-drive-file").click();
  };

  const handleFileUpload = (files) => {
    for (let index = 0; index < files.length; index++) {
      addFile(files[index], folderId ? folderId : null);
    }
  };

  return (
    <>
      <div className={"py-20 flex flex-col items-center text-white"}>
        <span>Hey, seems a bit light in here!</span>
        <p>
          {" "}
          {content
            ? content
            : "There is nothing in your upload. Let's add some items."}
        </p>
      </div>
      <div className="flex justify-center items-center gap-10">
        <button
          onClick={openAddFolder}
          className="border border-white rounded-md p-4"
        >
          <FaFolderPlus className="text-[50px]" color="white" />
        </button>
        {id && (
          <>
            <input
              className={`hidden`}
              id={"upload-drive-file"}
              onChange={(e) => handleFileUpload(e.target.files)}
              multiple
              type="file"
              name="img"
            />
            <button
              onClick={openUploadMOdal}
              className="border border-white rounded-md p-4"
            >
              <MdUpload className="text-[50px]" color="white" />
            </button>
          </>
        )}
      </div>

      {showAddFolder && (
        <AddFolderModal
          isPaid={id ? false : true}
          isOpen={showAddFolder}
          closeModal={closeAddFolder}
        />
      )}
    </>
  );
}

export default EmptyPageDrive;
