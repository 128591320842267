import axios from "axios";
import { API_URL } from "../constants/constants";
import localStorage from "../utils/localStorage";
import toast from '../utils/toast'

const Axios = axios.create({
  baseURL: API_URL,
});

// Add a request interceptor
Axios.interceptors.request.use(
  function (config) {
    config.headers = {
      ...config.headers,
      "ngrok-skip-browser-warning": "69420",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
Axios.interceptors.response.use(
  function (response) {
    // For status code with 2xx
    return response;
  },
  function (error) {
    // Status code outside the range of 2xx
    if (error && error.response && error.response.status === 401) {
      // toast.error("You are unauthorized please login again.");
      // window.location.href = '/auth/login';
      // localStorage.removeItem("user");
      // localStorage.removeItem("token");
      // window.location.href = 'https://creatorstock.io/login';
      // window.location.href = window.location.host.includes("localhost:")
      //   ? `http://localhost:3000/?redirect=http://localhost:3000`
      //   : `https://auth.creatorstock.io/?redirect=https://cloud.creatorstock.io`;
      // window.location.href = '/auth/login'
    }
    return Promise.reject(error);
  }
);

export default Axios;
