import moment from "moment";
import { IoMdCheckmark } from "react-icons/io";

const AddonPlanCard = ({plan, style, buttontxt, theme = false, onClick = null, active}) => {
    return (
        <div className={`flex flex-col gap-4 ${style} ${active ? 'bg-theme-peach-100':''}`}>
            <div className={"flex flex-col gap-2 items-center"}>
                <span className={"text-xl font-bold"}>{plan.title}</span>
                <span className={"text-sm font-medium"}>{plan.package_price > 0 ? '$'+plan.package_price+'/month':'Free Plan'}</span>
                <span className={"text-sm text-white/50 flex items-center font-medium"}>{plan.sub_title}</span>
            </div>
            <div className={"w-full h-[1px] bg-border"}/>
            <div className={"flex flex-col gap-4 md:h-[220px]"}>
                {plan.heading &&
                    <div className="text-md">Features</div>
                }
                {plan.features_list.map((item, index) => {
                    return (
                        <div key={index} className={"text-sm flex gap-2 items-center"}>
                            <IoMdCheckmark className={"text-xl font-bold text-theme-peach-100"}/>
                            {item.text}
                        </div>
                    )
                })
                }
            </div>
            {!active && <div className={""}>
                {theme ?
                    <button
                        onClick={() => onClick('create')}
                        className={`w-full text-sm text-black font-medium h-10 flex justify-center items-center
                         bg-theme-peach-100 hover:bg-theme-peach-200 rounded-md`}>
                        {buttontxt}
                    </button> :
                    <button
                        onClick={() => onClick('create')}
                        className={`w-full text-sm text-white h-10 flex justify-center items-center
                         border border-border hover:bg-white hover:text-black rounded-md`}>
                        {buttontxt}
                    </button>}
            </div>}
            {active && !plan.subscription.cancel_schedule && <div className={""}>
                    <button
                        onClick={() => onClick('cancel')}
                        className={`w-full text-sm text-black bg-white h-10 flex justify-center items-center
                         border border-border rounded-md`}>
                        {buttontxt}
                    </button>
            </div>}
            {active && plan.subscription.cancel_schedule && !plan.subscription.parent_expiring && <div className={""}>
                    <button
                        onClick={() => onClick('keep')}
                        className={`w-full text-sm text-white h-10 flex justify-center items-center
                         border border-border rounded-md`}>
                        Keep Addon
                    </button>
            </div>}
            {active && plan.subscription?.cancel_schedule && <span className={"text-sm text-white/90 flex items-center font-medium"}>Expiring: {moment.unix(plan.subscription?.end_date).format('DD MMM YY')} </span>}
            {active && !plan.subscription?.cancel_schedule && <span className={"text-sm text-white/90 flex items-center font-medium"}>Renews: {moment.unix(plan.subscription?.end_date).format('DD MMM YY')} </span>}
        </div>
    )
}
export default AddonPlanCard;
