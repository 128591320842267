import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { IoClose } from "react-icons/io5";
import { connectAccount } from "../../../../service/service.user";
import toast from "react-hot-toast";

export default function ConnectAccountModal({ isOpen, closeModal }) {
  const [saving, setSaving] = useState(false);

  const submitDetails = () => {
    setSaving(true);
    connectAccount()
      .then((res) => {
        if (res.data.status == 200 && res.data.url) {
          window.location.href = res.data.url;
        } else {
          toast.error(res.data.message);
          setSaving(false);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.error);
        setSaving(false);
      });
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={closeModal}
        open={isOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className="w-full max-w-md transform overflow-hidden rounded-2xl bg-theme-3
                            p-6 text-left align-middle shadow-xl transition-all"
              >
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-white mb-5"
                >
                  <div className="flex justify-between items-center">
                    <span>Attention!</span>
                    <IoClose
                      className="cursor-pointer text-[20px]"
                      onClick={closeModal}
                    />
                  </div>
                </Dialog.Title>

                <div className="mt-2 w-full font-inter text-white">
                  On click of agree button, you will be redirected to the bank's
                  website to create your stripe account and connect your account
                  with us for direct payout.
                </div>

                <div className="mt-4 flex justify-end gap-2">
                  <Button
                    onClick={() => submitDetails()}
                    disabled={saving}
                    name={saving ? "Connecting..." : "Connect"}
                  />
                  <Button
                    onClick={closeModal}
                    disabled={saving}
                    name={"Cancel"}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

const Button = ({ onClick, disabled, name }) => {
  return (
    <button
      type="button"
      disabled={disabled}
      className={`inline-flex justify-center rounded-md px-4 py-2 text-sm font-medium focus:outline-none 
            focus-visible:ring-2 focus-visible:ring-transparent focus-visible:ring-offset-2 
            ${
              name === "Cancel"
                ? " bg-white hover:bg-white/90 text-theme-1"
                : " bg-theme-peach-200 hover:bg-theme-peach-100 text-white"
            }`}
      onClick={onClick}
    >
      {name}
    </button>
  );
};
