import React from "react";
import ThemeButton from "../../components/buttons/themeButton";

const EarningHeader = ({
  openUpdateBankModal,
  openConnectAccountModal,
  openWithdrawlModal,
  payoutLoading,
  downloadToCSV,
  connectedAccount,
}) => {
  return (
    <div className={"w-full flex justify-end mb-4 gap-2"}>
      <ThemeButton className={connectedAccount ? "!bg-green-400 !text-black" : ""} name={connectedAccount ? "Stripe Connected" : "Stripe Connect Account"} disabled={connectedAccount} onClick={openConnectAccountModal} />
      <ThemeButton name={"Update Bank"} onClick={openUpdateBankModal} />
      <ThemeButton
        disabled={payoutLoading}
        name={payoutLoading ? "Loading..." : "Withdraw"}
        onClick={openWithdrawlModal}
      />
      <ThemeButton name={"Download CSV"} onClick={downloadToCSV} />
    </div>
  );
};

export default EarningHeader;
