import Header from "./components/Header";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useContext, useEffect, useMemo, useState } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import * as Yup from "yup";
import Folders from "./components/Folder";
import {
  FileManagerContext,
  FileManagerContextProvider,
} from "../../context/FileManagerContext";
import Button from "../main/components/buttons/Button";
import { IoChevronBack } from "react-icons/io5";
import PurchaseModal from "./components/modal/PurchaseModal";
import File from "./components/Files";
import MainLoader from "../main/components/loading/MainLoader";
import DefaultSwitch from "../main/components/switch/defaultSwitch";
import Files from "./components/Files";
import ViewContentModal from "../main/components/modals/ViewContentModal";
import { downloadAllFile, downloadFile } from "../../utils/Utils";
import { UserContext } from "../../context/user.context";
import { MAIN_INTERFACE } from "../../constants/constants";
import localStorage from "../../utils/localStorage";
import { Formik, useFormik } from "formik";
import AuthInputFiled from "../components/inputs/input.auth";
import AuthButton from "../components/buttons/authButton";
import {
  resendVerificationCode,
  verifyUserOtp,
} from "../../service/userService";
import toast from "react-hot-toast";
import { addNewUser } from "../../service/service.user";
import Verification from "../auth/components/Verification";

const SubShared = () => {
  const {
    loadFolders,
    folderData,
    loadingData,
    folderNameData,
    folderInfo,
    folderError,
  } = useContext(FileManagerContext);
  const { login, user, logout } = useContext(UserContext);
  const [view, setView] = useState("grid");
  const [purchase, setPurchase] = useState(false);
  const [folders, setFolders] = folderData;
  const [isLoading, setIsLoading] = loadingData;
  const [formLoading, setFormLoading] = useState(false);
  const [showVerification, setShowVerification] = useState(false);
  const [customError, setCustomError] = useState({});
  const [folderName, setFolderName] = folderNameData;
  const [uuid, setUuid] = useState("");
  const [email, setEmail] = useState("");
  const [timer, setTimer] = useState(0);
  const [viewContent, setViewContent] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState(0);
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const token = localStorage.getItem("token");

  const closePurchaseModal = () => {
    setPurchase(!purchase);
  };

  const filterDataByType = useMemo(() => {
    const filterData = (type) =>
      folders.filter((item) => item.meta?.type == type);
    const files = filterData("file");
    const folderData = filterData("folder");
    return { files, folderData };
  }, [folders]);

  const openPurchaseModal = () => {
    if (!user.isAuthenticated) {
      localStorage.setItem("guest_path", location.pathname);
      window.location.href = "/auth/login";
    } else {
      setPurchase(!purchase);
    }
  };

  const openViewContent = (id) => {
    setUuid(id);
    setViewContent(!viewContent);
  };
  const callback = () => {
    setLoading(false);
  };

  const handleDownloadAll = (id) => {
    if (!user.isAuthenticated) {
      localStorage.setItem("guest_path", location.pathname);
      // window.location.href = MAIN_INTERFACE + '/auth/login?redirect=' + window.location.host;
      window.location.href = "/auth/login";
    } else {
      setLoading(true);
      downloadAllFile(id, "share", callback);
    }
  };

  const closeViewContent = () => {
    setViewContent(!viewContent);
  };

  useEffect(() => {
    if (params.subid && params.id) {
      loadFolders(params.id, true, params.subid);
    } else if (params.id) loadFolders(params.id, true, null);
  }, [params.id, params.subid]);

  const redirectUser = () => {
    localStorage.setItem("guest_path", location.pathname);
    // window.location.href = MAIN_INTERFACE + '/auth/login?redirect=' + window.location.host;
    window.location.href = "/auth/login";
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("Please enter a valid Email")
        .required("Email is required"),
    }),
    onSubmit: async (data) => {
      setFormLoading(true);
      await addNewUser(data)
        .then((response) => {
            setUserId(response.data.id);
            setEmail(data.email);
            setShowVerification(true);
            setFormLoading(false);
        })
        .catch((e) => {
          if (
            e.response &&
            e.response.data &&
            e.response.data.data &&
            e.response.data.data.msg === "User already exists"
          ) {
            toast.error("User already exists! Please login to continue.");
            navigate(`/auth/login?email=${data.email}`);
          } else {
            toast.error("Something went wrong!");
            setFormLoading(false);
          }
        });
    },
  });

  const { handleSubmit, touched, errors, getFieldProps } = formik;

  const submitVerification = async (verificationCode) => {
    setCustomError({});
    setFormLoading(true);
    await verifyUserOtp({
      verification_code: verificationCode,
      user_id: userId,
    })
      .then(async (res) => {
        if (res.status === 200 && res.data && res.data.user) {
          toast.success("Verification completed!");
          login(res.data);
          window.location.reload();
        } else if (res.data && res.data.error) {
          setCustomError(res.data.error);
          setFormLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setFormLoading(false);
      });
  };

  const startTimer = () => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 0) {
          clearInterval(interval);
          return 0;
        } else {
          return prevTimer - 1;
        }
      });
    }, 1000);
  };

  const resendOtp = async () => {
    if (timer !== 0) {
      return;
    }
    await resendVerificationCode({ user_id: userId })
      .then((res) => {
        toast.success("Code sent successfully!");
        setTimer(60);
        startTimer();
      })
      .catch((err) => {
        toast.error("Unable to resend code!");
        console.log(err);
      });
  };

  return (
    <div className="">
      <Header
        count={
          !folderError && folderData && folderData.length > 0
            ? folderData.length
            : 0
        }
        showCart={
          !folderError &&
          folderInfo &&
          folderInfo.type === 1 &&
          folderInfo.ownership == "shared_view"
        }
        onPurchase={openPurchaseModal}
        loading={loading}
        handleDownloadAll={() => handleDownloadAll(params.id)}
        showMyDrive={token ? true : false}
      />
      <div className="bg-theme-2">
        <div
          style={{ height: "calc(100vh - 76px)" }}
          className="overflow-y-auto px-[40px]"
        >
          {!isLoading && (
            <div className="text-white mt-5 px-4">
              {folderName ? (
                <div className={"flex justify-between items-center"}>
                  <div className="flex justify-start items-center gap-2 cursor-pointer px-1">
                    {params?.subid ? (
                      <Button
                        onClick={() => navigate(-1)}
                        icon={<IoChevronBack className="text-[18px]" />}
                      />
                    ) : null}
                    {folderName}
                  </div>
                </div>
              ) : (
                !folderError && (
                  <div className={"flex justify-between items-center"}>
                    <span className={"text-white font-medium"}>
                      Fetching...
                    </span>
                  </div>
                )
              )}
            </div>
          )}

          <div className={"p-4"}>
            <div>
              <div className={"flex flex-col gap-2 mb-2"}>
                <div className="">
                  {!isLoading && folderError ? (
                    <div className="flex justify-center items-center text-white h-[70vh]">
                      <h4 className="text-center whitespace-pre-line">{folderError}</h4>
                    </div>
                  ) : isLoading ? (
                    <MainLoader />
                  ) : folders && folders.length ? (
                    <div>
                      <div className={"flex flex-col gap-2 mb-2"}>
                        <div className="grid grid-cols-2 lg:grid-cols-4 gap-6">
                          {filterDataByType.folderData.map((item, index) => {
                            return (
                              <div key={index}>
                                <Folders key={item.id} item={item} />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      {filterDataByType?.files &&
                      filterDataByType.files?.length ? (
                        <div className="flex flex-col gap-2">
                          <div className={"flex justify-between items-center"}>
                            <span className={"text-white font-medium"}>
                              Files
                            </span>
                            <DefaultSwitch
                              enabled={enabled}
                              setEnabled={setEnabled}
                            />
                          </div>
                          <Files
                            grid={4}
                            openViewContent={
                              !user.isAuthenticated
                                ? redirectUser
                                : openViewContent
                            }
                            downloadFile={
                              !user.isAuthenticated
                                ? redirectUser
                                : downloadFile
                            }
                            data={filterDataByType?.files}
                            enabled={enabled}
                          />
                        </div>
                      ) : null}
                    </div>
                  ) : (
                    <div className="flex justify-center items-center text-white mt-4">
                      No content found
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {purchase && (
            <PurchaseModal
              id={params.id}
              folder={folderInfo}
              isOpen={purchase}
              closeModal={closePurchaseModal}
            />
          )}
          {viewContent && (
            <ViewContentModal
              file={true}
              id={uuid}
              isOpen={viewContent}
              closeModal={closeViewContent}
            />
          )}
        </div>
      </div>
      {!token && (
        <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex justify-center items-center z-50">
          {!showVerification ? (
            <div className="bg-theme-3 p-8 rounded-lg shadow-xl flex flex-col gap-4 max-w-md w-full mx-4">
              <h1 className="text-white text-2xl font-bold text-center">
                Please enter your email for access
              </h1>
              <form>
                <div className="mt-8 mb-3">
                  <AuthInputFiled
                    error={touched.email && errors.email}
                    register={getFieldProps("email")}
                    label={"Email"}
                    name={"email"}
                    placeholder={"email@xyz.com"}
                  />
                </div>
                <div className="flex justify-center items-center mt-4">
                  <AuthButton
                    disabled={formLoading}
                    name="Verify Access"
                    onClick={handleSubmit}
                    type={"submit"}
                    isLoading={formLoading}
                  />
                </div>
              </form>
            </div>
          ) : (
            <Verification
              timer={timer}
              isLoading={formLoading}
              email={email}
              submitVerification={submitVerification}
              resendOtp={resendOtp}
              error={customError.verification_code}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default function Shared(props) {
  return (
    <FileManagerContextProvider>
      <SubShared {...props} />
    </FileManagerContextProvider>
  );
}
