import React, { useEffect, useRef, useState } from "react";
import PageHeading from "../components/pageHeading/PageHeading";
import EarningTable from "../components/table/earningTable";
import EarningCard from "./components/earningCard";
import AddBankModal from "../components/modals/AddBankModal";
import ConnectAccountModal from "../components/modals/ConnectAccountModal";
import { LIMIT, SUCCESS200, SUCCESS201 } from "../../../constants/constants";
import {
  checkBankDetails,
  userTransactions,
} from "../../../service/service.user";
import moment from "moment";
import EarningHeader from "./components/earningHeader";
import WithdrawlModal from "../components/modals/WithdrawlModal";
import LoadMore from "../components/loadMore/LoadMore";
import MainLoader from "../components/loading/MainLoader";
import toast from "react-hot-toast";

const Earnings = () => {
  const [transactions, setTransactions] = useState([]);
  const [walletBalance, setWalletBalance] = useState({
    totalBalance: "",
    walletLifetimeBalance: "",
  });
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [fetchNext, setFetchNext] = useState(false);
  const [page, setPage] = useState(0);
  const [payoutLoading, setPayoutLoading] = useState(false);
  const [updateBankModal, setUpdateBankModal] = useState(false);
  const [connectAccountModal, setConnectAccountModal] = useState(false);
  const [withdrawlModal, setWithdrawlModal] = useState(false);
  const [connectedAccount, setConnectedAccount] = useState(false);

  const openWithdrawlModal = () => {
    loadWithdraw();
  };

  const closeWithdrawlModal = () => {
    setWithdrawlModal(!withdrawlModal);
  };

  const openUpdateBankModal = () => {
    setUpdateBankModal(!updateBankModal);
  };

  const closeUpdateBankModal = () => {
    setUpdateBankModal(!updateBankModal);
  };

  const openConnectAccountModal = () => {
    setConnectAccountModal(!connectAccountModal);
  };

  const closeConnectAccountModal = () => {
    setConnectAccountModal(!connectAccountModal);
  };

  const modalRef = useRef();

  useEffect(() => {
    setLoading(true);
    getUserTransactions(0);
  }, []);

  const getUserTransactions = async (page) => {
    await userTransactions({ offset: page ? page * LIMIT : page, limit: LIMIT })
      .then((res) => {
        if (
          (res.status === SUCCESS200 || res.status === SUCCESS201) &&
          res.data &&
          res.data.status === SUCCESS200
        ) {
          setWalletBalance({
            totalBalance: res.data?.walletBalance,
            walletLifetimeBalance: res.data?.walletLifetimeBalance,
          });
          setConnectedAccount(res.data?.connected);
          if (page === 0) {
            setTransactions(res.data.transactions);
          } else {
            setTransactions(transactions.concat(res.data.transactions));
          }
          setPage(page + 1);
          setFetchNext(true);
        }
        if (
          !(res.data && res.data.contents && res.data.contents.length === LIMIT)
        ) {
          setFetchNext(false);
        }
      })
      .finally(() => {
        setLoading(false);
        setPageLoading(false);
      });
  };

  const downloadToCSV = () => {
    const data = transactions.map((item) => {
      return [
        item.transaction_uid,
        moment(item.createdAt).format("MMM DD YYYY hh:mm A"),
        item.transaction_type,
        item.client ? item.client.name : "",
        `£ ${parseFloat(item.amount).toFixed(2)}`,
      ];
    });
    let csvContent =
      "data:text/csv;charset=utf-8,OrderId,Date,Type,Client,Amount\n" +
      data.map((e) => e.join(",")).join("\n");

    const encodedUri = encodeURI(csvContent);
    window.location.href = encodedUri;
  };

  const loadWithdraw = async () => {
    setPayoutLoading(true);
    await checkBankDetails()
      .then((res) => {
        loadPayout();
      })
      .catch((err) => {
        if (err.response.data.details) {
          openUpdateBankModal();
        } else {
          openUpdateBankModal();
        }
      })
      .finally(() => setPayoutLoading(false));
  };

  const loadPayout = () => {
    setWithdrawlModal(!withdrawlModal);
  };

  return (
    <div>
      <PageHeading heading={"Earnings"} profile />
      {loading ? (
        <MainLoader />
      ) : (
        <>
          <EarningCard walletBalance={walletBalance} />
          <div className={"p-4 h-[calc(100vh-70px)] overflow-y-auto"}>
            <EarningHeader
              connectedAccount={connectedAccount}
              openUpdateBankModal={openUpdateBankModal}
              openWithdrawlModal={openWithdrawlModal}
              payoutLoading={payoutLoading}
              downloadToCSV={downloadToCSV}
              openConnectAccountModal={openConnectAccountModal}
            />
            <EarningTable transactions={transactions} />
          </div>
        </>
      )}

      {!pageLoading && fetchNext && (
        <LoadMore
          onClick={() => {
            setPageLoading(true);
            getUserTransactions(page);
          }}
        />
      )}
      {pageLoading && <MainLoader />}
      <AddBankModal
        isOpen={updateBankModal}
        modalRef={modalRef}
        closeModal={closeUpdateBankModal}
      />
      <ConnectAccountModal
        isOpen={connectAccountModal}
        closeModal={closeConnectAccountModal}
      />
      <WithdrawlModal
        isOpen={withdrawlModal}
        closeModal={closeWithdrawlModal}
      />
    </div>
  );
};

export default Earnings;
