import React, {Fragment, useState} from "react";
import {useRef} from "react";
import emptyFile from "../../../../../../assets/json/empty.json";
import Lottie from "lottie-react";

const UploadWatermark = ({multiple = true, info = "", handleFileUpload, label = ""}) => {
    const fileRef = useRef();
    const [dragActive, setDragActive] = useState(false);

    const handleUpload = (target) => {
        console.log("clicked")
        if (fileRef.current && target.files.length > 0) {
            console.log(target.files);
            handleFileUpload(target.files);
            fileRef.current.value = '';
        }
    }

    const handleDrag = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleFileUpload(e.dataTransfer.files);
        }
    };
    return (
        <Fragment>
            {!multiple ?
                <input
                    className={`hidden`}
                    id={"upload"}
                    onChange={(e) => handleUpload(e.target)}
                    ref={fileRef}
                    type="file"
                    name="img"
                    accept={".png, .jpg"}
                />
                :
                <input
                    className={`hidden`}
                    id={"upload"}
                    onChange={(e) => handleUpload(e.target)}
                    multiple
                    ref={fileRef}
                    type="file"
                    name="img"
                />}
            <label
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}
                htmlFor={"upload"}
                className={"text-gray-200 text-2xl font-medium w-full h-full"}
            >
                <span className={`w-full flex flex-col justify-center
                 items-center text-center p-2 font-inter h-full`}>
                  <Lottie animationData={emptyFile} loop={true} className={"w-20"}/>
                    <h3 className={"text-[20px] font-medium"}>Upload your Logo or Watermark</h3>
                    {/*<p className={"text-[14px]"}>Your files here & Browse to upload</p>*/}
                    <p className={"text-[10px] text-indigo-400"}>
                        {info ?
                            info :
                            "Only JPEG, PNG, GIF and PDF files with max size of 100 MB."
                        }
                    </p>
                </span>
            </label>
        </Fragment>
    )
}
export default UploadWatermark;
