import React, { Fragment, useState } from "react";
import { IoEye } from "react-icons/io5";
import Thumb from "../../../../assets/images/creator.jpeg";
import { FiDownload } from "react-icons/fi";
import {
  MdOutlineInsertDriveFile,
  MdOutlineSettingsBackupRestore,
} from "react-icons/md";
import { FaBoxArchive } from "react-icons/fa6";
import { RiDeleteBin5Fill } from "react-icons/ri";
import toast from "react-hot-toast";

const FileGridView = ({
  hide = false,
  grid = 2,
  data,
  archiveFile,
  deleteFile,
  restoreFile,
  downloadFile,
  openViewContent,
}) => {
  const [hoverId, setHoverId] = useState(null);
  const [playVideo, setPlayVideo] = useState(false);
  return (
    // <div className={`grid grid-cols-${grid} md:grid-cols-${grid + 2} lg:grid-cols-${grid + 4}  gap-4`}>
    <div className={`grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-4`}>
      {data.map((item, index) => {
        return (
          <div
            key={index}
            className="bg-theme-5 rounded-md overflow-hidden font-inter"
          >
            <div className={"relative overflow-hidden"}>
              {!item?.meta?.published && (
                <div className="absolute border-0 bg-transparent left-1 top-1">
                  <span className="rounded-md bg-yellow-500 py-1 px-2 text-xs text-white">
                    PROCESSING
                  </span>
                </div>
              )}
              <div className="">
                {item?.meta?.is_media ? (
                  item?.meta?.file_type === "video" && playVideo && item?.id === hoverId ? (
                    <video
                      src={item?.meta?.preview}
                      className={
                        "w-full h-[150px] md:h-[150px] lg:h-[150px] rounded-sm object-cover"
                      }
                      autoPlay={true}
                      muted={true}
                      loop={true}
                    />
                  ) : (
                    <img
                      onError={(e) => {
                        e.target.src = Thumb;
                      }}
                      src={item?.meta?.image || Thumb}
                      alt={"thumb"}
                      className={
                        "w-full h-[150px] md:h-[150px] lg:h-[150px] rounded-sm object-cover"
                      }
                    />
                  )
                ) : (
                  <MdOutlineInsertDriveFile
                    className={
                      "w-full h-[150px] lg:h-[200px] text-black/40 rounded-md object-cover"
                    }
                  />
                )}
              </div>
              <div
                onClick={(ev) => {
                  if (!item?.meta?.published) {
                    toast.error("Please wait while file is being processed!");
                    return;
                  }
                  ev.stopPropagation();
                  if (item?.meta.is_media) {
                    openViewContent(item.id);
                  }
                }}
                className="absolute opacity-0 bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-black/50
                                transition duration-300 ease-in-out hover:opacity-100 flex flex-col cursor-pointer"
              >
                <div
                  className={"flex flex-col p-2 gap-2 justify-center items-end"}

                onMouseEnter={() => {
                    setHoverId(item.id);
                    setPlayVideo(true)}}
                onMouseLeave={() => {
                    setHoverId(null);
                    setPlayVideo(false)}}
                >
                  {item.meta.is_media && (
                    <span
                      className={`w-5 h-5 flex justify-center cursor-pointer items-center text-white text-xs
                                            bg-black/80 hover:bg-theme-peach-100 rounded-md`}
                      onClick={(ev) => {
                        if (!item?.meta?.published) {
                          // toast.error("Please wait while file is being processed!");
                          return;
                        }
                        ev.stopPropagation();
                        openViewContent(item.id);
                      }}
                    >
                      <IoEye />
                    </span>
                  )}
                  <span
                    onClick={(ev) => {
                      ev.stopPropagation();
                      downloadFile(item.id);
                    }}
                    className={`w-5 h-5 flex justify-center cursor-pointer items-center text-white text-xs
                                        bg-black/80 hover:bg-theme-peach-100 rounded-[4px]`}
                  >
                    <FiDownload />
                  </span>
                  {hide ? null : (
                    <Fragment>
                      {item.meta?.deleted_at ? (
                        <span
                          onClick={(ev) => {
                            ev.stopPropagation();
                            restoreFile(item.id);
                          }}
                          className={`w-5 h-5 flex justify-center cursor-pointer items-center text-white text-xs
                                        bg-black/80 hover:bg-theme-peach-100 rounded-[4px]`}
                        >
                          <MdOutlineSettingsBackupRestore />
                        </span>
                      ) : (
                        <span
                          onClick={(ev) => {
                            ev.stopPropagation();
                            archiveFile(item.id);
                          }}
                          className={`w-5 h-5 flex justify-center cursor-pointer items-center text-white text-xs
                                        bg-black/80 hover:bg-theme-peach-100 rounded-[4px]`}
                        >
                          <FaBoxArchive />
                        </span>
                      )}
                      <span
                        onClick={(ev) => {
                          ev.stopPropagation();
                          deleteFile(item.id);
                        }}
                        className={`w-5 h-5 flex justify-center cursor-pointer items-center text-white text-xs
                                        bg-black/80 hover:bg-theme-peach-100 rounded-[4px]`}
                      >
                        <RiDeleteBin5Fill />
                      </span>
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
            <div className={"p-2 flex flex-col gap-1"}>
              <span
                title={item.meta?.name}
                className={
                  "text-white text-xs lg:text-sm font-inter font-medium truncate pr-5"
                }
              >
                {item.meta?.name}
              </span>
              <div className={"flex flex-wrap gap-1"}>
                {item.tags?.length &&
                  item.tags.map((item, index) => {
                    return (
                      <span
                        key={index}
                        className={
                          "text-gray-400 text-sm font-medium h-4 flex items-center"
                        }
                      >
                        #{item}
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default FileGridView;
