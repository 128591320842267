const IMAGE_BASE_URL = "https://cdn.creatorstock.io";

// DEVELOPMENT ENVIRNOMENT
const API_BASE_URL = "https://api.cstock.dev/api";
const API_URL = "https://api.cstock.dev/api";
const MAIN_INTERFACE = "https://cstock.dev/";
const MAIN_DOMAIN = "cstock.dev";

// LOCAL ENVIRNOMENT
// const API_BASE_URL = "http://localhost:8000/api";
// const API_URL = "http://localhost:8000/api";
// const MAIN_INTERFACE = "http://localhost:3000/";
// const MAIN_DOMAIN = "localhost:3000";

// NGROK ENVIRNOMENT
// const API_BASE_URL = "https://boar-humorous-lizard.ngrok-free.app/api";
// const IMAGE_BASE_URL = "https://cdn.creatorstock.io";
// const API_URL = "https://boar-humorous-lizard.ngrok-free.app/api";
// const MAIN_INTERFACE = "https://creatorstock.io/";
// const MAIN_DOMAIN = "creatorstock.io";

// PRODUCTION ENVIRNOMENT
// let API_BASE_URL = "https://api.creatorstock.io/api";
// let API_URL = "https://api.creatorstock.io/api";
// let MAIN_INTERFACE = "https://creatorstock.io/";
// let MAIN_DOMAIN = "creatorstock.io";
let maxUpload = 1048576 * 10; // 10 mb
const LIMIT = 22;
const SUCCESS200 = 200;
const SUCCESS201 = 201;
const CURRENCY_SYMBOL = '£';
 
const TAGS_LIST = [
    {text: "Holiday 🌴", slug:"holiday"},
    {text: "Beach 🏖️", slug: "beach"},
    {text: "Nature 🌲", slug: "nature"},
    {text: "Food 🥑", slug: "food"},
    {text: "Social 👫", slug: "social"},
    {text: "Partying 💃", slug: "partying"},
    {text: "Travel ✈️ ", slug: "travel"},
    {text: "Summer ☀️", slug: "summer"},
    {text: "Festival 👯‍♂️", slug: "festival"},
    {text: "Flights ✈️ ", slug: "Flights"},
    {text: "Winter 🥶", slug: "winter"},
];

export { API_BASE_URL, IMAGE_BASE_URL, API_URL, LIMIT, SUCCESS200, SUCCESS201, CURRENCY_SYMBOL,TAGS_LIST, MAIN_INTERFACE,MAIN_DOMAIN};