import React from 'react';
import emptyFile from '../../../../assets/json/empty.json'
import Lottie from 'lottie-react';

function EmptyPage({ content }) {
    return (
        // <div className="h-[300px] flex flex-col justify-center items-center text-white text-[14px]">
        //     {content}
        // </div>
        <div className={"py-20 flex flex-col items-center text-white"}>
            <Lottie animationData={emptyFile} loop={true} className={"w-44"} />
            <span>Hey, seems a bit light in here!</span>
            <p> {content ? content : "There is nothing in your upload. Let's add some items."}</p>
        </div>
    );
}

export default EmptyPage;