import React, { useEffect, useState } from 'react'
import PageHeading from '../components/pageHeading/PageHeading'
import { deleteContent, listArchiveContent, restoreContent } from '../../../service/service.content'
import toast from 'react-hot-toast'
import { SUCCESS200, SUCCESS201 } from '../../../constants/constants'
import { convertSize, downloadFile } from '../../../utils/Utils'
import Files from '../drive/components/files'
import DefaultSwitch from '../components/switch/defaultSwitch'
import MainLoader from '../components/loading/MainLoader'
import moment from 'moment'
import ViewContentModal from '../components/modals/ViewContentModal'
import CommonModal from '../components/modals/CommonModal'

const Archived = () => {
  const [archiveData, setArchiveData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [enabled, setEnabled] = useState(false)
  const [uuid, setUuid] = useState("");
  const [viewContent, setViewContent] = useState(false);
  const [deleteFileModal, setDeleteFileModal] = useState(false);
  const [archiveModal, setArchiveModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const archiveContentData = () => {
    setIsLoading(true);

    listArchiveContent()
      .then((res) => {
        if ((res.status === SUCCESS200 || res.status === SUCCESS201) && res.data && res.data.status === SUCCESS200) {
          const newArchivedContents = res.data.deleted_contents.map((content) => {
            return {
              id: content.uuid,
              meta: {
                name: content.original_name || "-",
                image: content.thumbnail || "",
                is_media: content.is_media,
                type: "file",
                time: moment(content.created_at).format("hh:mm A"),
                date: moment(content.created_at).format("MM/DD/YYYY"),
                size: content.file_size ? convertSize(content.file_size) : convertSize(0),
                rejectedReason: content.rejectedReason,
                approved: content.approved,
                file_type: content.file_type,
                preview: content.preview,
                processing:
                  content.published === 5 || content.isRejected || content.deleted_at
                    ? "#fa3e3e"
                    : content.published === 1
                      ? "#1d2d40"
                      : "#fc723b",
                published: content.published,
                isRejected: content.isRejected,
                deleted_at: content.deleted_at,
              },
            };
          });

          // Set the mapped data for archived content
          setArchiveData([...newArchivedContents]);
        } else {
          setArchiveData([]);
          toast.error("Unable to get archived content data!!");
        }
      })
      .catch((err) => {
        console.log(err);
        setArchiveData([]);
        toast.error("Unable to get archived content data!!");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    archiveContentData()
  }, [])

  const openArchiveModal = (id) => {
    setUuid(id)
    setArchiveModal(!archiveModal)
  }
  const openViewContent = (id) => {
    setUuid(id)
    setViewContent(!viewContent)
  }

  const openDeleteFileModal = (id) => {
    setUuid(id);
    setDeleteFileModal(true)
  }

  const closeViewContent = () => {
    setViewContent(!viewContent)
  }
  const closeDeleteFileModal = () => {
    setDeleteFileModal(false)
  }
 

  const restoreFile = (uuid) => {

    restoreContent(uuid)
      .then((res) => {
        if (res && res.data && (res.data.status === SUCCESS200 || res.data.status === SUCCESS201)) {
          toast.success("Content restored successfully");
          archiveContentData();
        } else {
          toast.error("Unable to restore Content");
        }
      })
      .catch((err) => console.log(err));
  };

  const deleteFile = () => {
    deleteContent(uuid)
      .then((res) => {
        if (res && res.data && (res.data.status === SUCCESS200 || res.data.status === SUCCESS201)) {
          toast.success("Content deleted successfully");
          archiveContentData();
          closeDeleteFileModal()
        } else {
          toast.error("Unable to delete Content");
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <div>
      <PageHeading
        heading={"Archived content"}
        profile
      />

      <div className={"flex flex-col p-4"}>
        {isLoading ? (<MainLoader />) : archiveData && archiveData?.length ?
          <div className={"flex flex-col gap-4"}>
            <div className='flex flex-col gap-2'>
              <div className={"flex justify-between items-center"}>
                <span className={"text-white font-medium"}>
                  Files
                </span>
                <DefaultSwitch enabled={enabled} setEnabled={setEnabled} />
              </div>
              <Files
                archiveFile={openArchiveModal}
                openViewContent={openViewContent}
                deleteFile={openDeleteFileModal}
                restoreFile={restoreFile}
                downloadFile={downloadFile}
                data={archiveData}
                enabled={enabled}
              />
            </div>
            {viewContent &&
              <ViewContentModal
                file={true}
                id={uuid}
                isOpen={viewContent}
                closeModal={closeViewContent}
              />
            }
            {deleteFileModal &&
              <CommonModal
                title={"Delete File"}
                message={"Deleting will permanently remove this file from system and cannot be recovered. Are you sure you want to continue?"}
                isOpen={deleteFileModal}
                btnText={"Delete"}
                handleConfirm={deleteFile}
                closeModal={closeDeleteFileModal} />
            }
          </div>
          : ''
        }
      </div>
    </div>
  )
}

export default Archived
